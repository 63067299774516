import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { Avatar } from '@components/atoms/Avatar/Avatar';
import { Link } from '@components/atoms/Link/Link';
import { User } from '@lib/graphql/generated';

export interface AuthorCardProps extends PropsWithClassProps<'avatar' | 'heading' | 'description' | 'card' | 'uplink'> {
  author: User;
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive';
}

export const AuthorCard = ({ classProps, author }: AuthorCardProps) => {
  return (
    <div className={classProps?.card}>
      <Link
        classProps={{
          root: (author.profile ? '' : 'pointer-events-none ') + 'relative inline-block h-32 w-32 shrink-0',
        }}
        href={author.profile ? author.profile : '#'}
      >
        {author.avatar ? (
          <Avatar classProps={{ root: 'w-full h-full' }} avatar={author.avatar} alt={author.name} />
        ) : (
          <div className="size-full rounded-full bg-gray-200"></div>
        )}
      </Link>
      <div className={classProps?.description}>
        <div className={classProps?.heading}>{author.name}</div>
        {author.description && (
          <div className="mx-auto mt-3 max-w-md md:mx-0" dangerouslySetInnerHTML={{ __html: author.description }}></div>
        )}
      </div>
    </div>
  );
};
