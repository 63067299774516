import React, { FC } from 'react';
import { User } from '@lib/graphql/generated';
import { AuthorBox } from '../AuthorBox/AuthorBox';

export interface AuthorBoxProps {
  authors: User[];
}

export const AuthorBoxBottom: FC<AuthorBoxProps> = ({ authors }) => {
  return authors.length > 0 ? (
    <section className={'mt-16'}>
      <div className={'inset-x-0 pb-8 text-center text-2xl'}>
        <span className={'px-2.5 font-bold'}>{authors.length > 1 ? 'ÜBER DIE AUTOREN' : 'ÜBER DEN AUTOR'}</span>
      </div>

      <AuthorBox
        classProps={{
          root: 'text-border border-gray-500 rounded-md bg-white p-4 py-12 text-center md:text-left shadow-card-sm',
          card: 'md:flex items-center justify-center gap-6',
          avatar: ' rounded-full',
          description: 'text-gray-500 relative',
          uplink: 'font-bold underline',
          heading: 'text-black font-bold text-xl mt-2.5 ',
        }}
        authors={authors}
      />
    </section>
  ) : null;
};
