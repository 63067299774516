import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { PropsWithClassProps, Link, SocialMediaType } from '@vgn-medien-holding/vgn-react-components';

interface SocialShareButtonProps extends PropsWithChildren, PropsWithClassProps<'link'> {
  link: Link;
  type: SocialMediaType;
}

export const SocialShareButton: FC<SocialShareButtonProps> = ({ children, classProps, link, type }) => {
  let baseUrl;

  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  if (type === 'Facebook') {
    baseUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
  } else if (type === 'Twitter') {
    baseUrl = 'https://twitter.com/intent/tweet?text=' + url;
  } else if (type === 'LinkedIn') {
    baseUrl = 'https://www.linkedin.com/shareArticle?mini=true&url=' + url;
  } else if (type === 'MailTo') {
    baseUrl = 'mailto:?subject=' + link.title + '&body=' + url;
  } else {
    baseUrl = '';
  }

  return (
    <a href={`${baseUrl}`} target="_blank" rel="noreferrer" className={classProps?.link} aria-label={type}>
      {children}
    </a>
  );
};
