import React from 'react';
import { useRouter } from 'next/router';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { SocialShareButton } from '@components/atoms/SocialShareButton/SocialShareButton';

export const SocialButtons = ({ classProps }: PropsWithClassProps) => {
  const linkStyle = 'grid h-9 w-9 items-center justify-center rounded-full bg-white text-gray-500 shadow-card-sm';
  const { asPath } = useRouter();
  return (
    <div className={twMerge('flex gap-4 lg:absolute lg:left-0 lg:top-0 lg:grid lg:pl-1', classProps?.root)}>
      <SocialShareButton classProps={{ link: linkStyle }} link={{ url: asPath, title: '' }} type={'Facebook'}>
        <Image
          src={'/assets/icons/icon-facebook-simple.svg'}
          alt={'Facebook'}
          width={16}
          height={16}
          classProps={{ root: 'h-4 w-4', container: 'h-auto' }}
          copyright=""
        />
      </SocialShareButton>
      <SocialShareButton classProps={{ link: linkStyle }} link={{ url: asPath, title: '' }} type={'Twitter'}>
        <Image
          src={'/assets/icons/icon-twitter.svg'}
          alt={'Twitter'}
          width={16}
          height={16}
          classProps={{ root: 'h-4 w-4', container: 'h-auto' }}
          copyright=""
        />
      </SocialShareButton>
    </div>
  );
};
