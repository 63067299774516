/* This example requires Tailwind CSS v2.0+ */
import React, { FC } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { User } from '@lib/graphql/generated';
import { AuthorCard } from '../../molecules/AuthorCard/AuthorCard';

export interface AuthorBoxProps
  extends PropsWithClassProps<'avatar' | 'heading' | 'description' | 'card' | 'title' | 'uplink'> {
  authors: User[];
}

export const AuthorBox: FC<AuthorBoxProps> = ({ classProps, authors }) => {
  return (
    <div className={classProps?.root}>
      {authors.map((author, index) => (
        <AuthorCard key={index} classProps={classProps} author={author} />
      ))}
    </div>
  );
};
