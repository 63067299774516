import React, { FC } from 'react';
import { DateTime } from '@vgn-medien-holding/vgn-react-components';

export interface ArticleDateProps {
  createdDate: Date;
  updatedDate?: Date;
}

export const ArticleDate: FC<ArticleDateProps> = ({ createdDate, updatedDate }) => {
  return (
    <p className={'text-gray-500'}>
      <span>
        Ver&ouml;ffentl. am <DateTime date={createdDate} formatOptions={{ dateStyle: 'medium' }} />
      </span>
      {updatedDate && (
        <>
          <span> / Aktualisiert am </span>
          <DateTime classProps={{ root: 'font-bold' }} date={updatedDate} formatOptions={{ dateStyle: 'medium' }} />
        </>
      )}
    </p>
  );
};
