import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface ArticleInfoBoxProps extends PropsWithClassProps {
  children: React.ReactNode;
}

export const ArticleInfoBox = ({ children, classProps }: ArticleInfoBoxProps) => {
  const basicTileStyling = twMerge(
    'h-full rounded-2xl bg-white p-4 shadow-card-sm text-center content-center h-16 inline-grid items-center gap-x-2',
    classProps?.root,
  );
  return <div className={basicTileStyling}>{children}</div>;
};

export interface ArticleInfoBoxLineProps extends PropsWithClassProps {
  text: string;
  type: 'heading' | 'default';
}

const ArticleInfoBoxLine = ({ text, type }: ArticleInfoBoxLineProps) => {
  const lineStyle = {
    heading: 'font-herokid font-semibold text-sm leading-body',
    default: 'font-proxima-nova uppercase text-2xs leading-body tracking-2px text-gray-500',
  };
  return <div className={lineStyle[type]}>{text}</div>;
};

ArticleInfoBox.Line = ArticleInfoBoxLine;
