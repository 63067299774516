import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Link, LinkProps } from '@components/atoms/Link/Link';

export interface BreadcrumbsProps {
  breadcrumbs: LinkProps[];
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => (
  <div className="flex flex-wrap text-sm font-bold uppercase">
    {breadcrumbs?.map((link, index) => (
      <div className="flex h-5 items-center divide-x divide-gray-600" key={index}>
        {index > 0 && <span className="self-start">{''}</span>}
        <Link
          href={link.href || ''}
          className={twMerge(
            'pr-4 hover:underline',
            index === breadcrumbs.length - 1 ? 'px-4 text-primary' : index > 0 && 'px-6',
          )}
        >
          {link.title}
        </Link>
      </div>
    ))}
  </div>
);
