import React from 'react';
import { ArticleInfoBox } from '@components/atoms/ArticleInfoBox/ArticleInfoBox';
import { Avatar } from '@components/atoms/Avatar/Avatar';

export const ArticleInfos = ({ article }) => {
  return (
    <div className="flex flex-wrap gap-3">
      {article?.authors?.length > 0 && !article?.hide_authors && (
        <ArticleInfoBox classProps={{ root: 'inline-flex text-left' }}>
          <Avatar avatar={article?.authors?.[0]?.avatar} alt={'Foto von ' + article?.authors?.[0]?.name} />
          <div>
            <ArticleInfoBox.Line text={article?.authors?.[0]?.name} type="heading"></ArticleInfoBox.Line>
            <ArticleInfoBox.Line text="Autor" type="default"></ArticleInfoBox.Line>
          </div>
        </ArticleInfoBox>
      )}
      <ArticleInfoBox>
        <ArticleInfoBox.Line text="Lesezeit" type="heading"></ArticleInfoBox.Line>
        <ArticleInfoBox.Line text={article?.reading_time + ' min'} type="default"></ArticleInfoBox.Line>
      </ArticleInfoBox>
    </div>
  );
};
